import React from "react"
import htmlParser from "html-react-parser"
import styled from "styled-components"
import { remCalc, pageMargins, color } from "../shared/styles"

const StyledContainer = styled.div`
  padding-top: ${remCalc(100)};
  padding-bottom: ${remCalc(100)};
  ${pageMargins};
  &.section_contact {
    padding-left: 0;
    padding-right: 0;
    & h2,
    p {
      ${pageMargins}
      color: ${color.bg};
    }
  }
  @media only screen and (max-width: 640px) {
    padding-top: ${remCalc(50)};
    padding-bottom: ${remCalc(50)};
  }
`

const GeneralSection = ({ html, className }) => {
  return (
    <StyledContainer className={className}>{htmlParser(html)}</StyledContainer>
  )
}

export default GeneralSection
