import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={38} height={16} viewBox="0 0 38 16" {...props}>
      <title>{"Path"}</title>
      <path
        stroke="#ffffff"
        strokeWidth={2}
        d="M37.385 1L19 14.22.615 1"
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgComponent
