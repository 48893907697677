import React from "react"
import GeneralSection from "../GeneralSection"
import styled from "styled-components"
import { remCalc, arrowMargins } from "../../shared/styles"
import HeroArrow from "../../images/HeroArrow"
import { bounce } from "../../shared/animation"

const StyledContainer = styled.section`
  height: 100vh;
  min-height: 35rem;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;

  & svg {
    position: absolute;
    bottom: 7vh;
    left: 0;
    animation: ${bounce} 2s ease infinite;
    ${arrowMargins}
  }
  h2 {
    margin-bottom: 0;
    margin-top: 6rem;
  }
  @media only screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    min-height: 41rem;
    padding-top: 4rem;

    & svg {
      position: relative;
      bottom: unset;
      left: unset;
    }
    h2 {
      margin-top: 0rem;
    }
  }
  @media only screen and (max-width: 640px) {
    min-height: 100vh;
    h2 {
      margin-top: 0rem;
      font-size: 1.8rem;
      line-height: 2.5rem;
      width: 95%;
    }
  }
`

const FirstSection = ({ html }) => {
  return (
    <StyledContainer>
      <GeneralSection html={html} />
      <HeroArrow />
    </StyledContainer>
  )
}

export default FirstSection
