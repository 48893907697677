import styled, { keyframes } from "styled-components"

export const bounce = () => {
  return keyframes`
  0% {
    transform:  translate(0, -50%);
  }

  50% {
    transform:  translate(0, 0%);
  }
  100% {
  
     transform:  translate(0, -50%);
  }
`
}
