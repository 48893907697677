import React, { useState } from "react"
import DownArrow from "../../images/DownArrow"
import htmlParser from "html-react-parser"

const QA = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false)

  const closeItem = () => {
    setIsOpen(prevState => !prevState)
  }

  return (
    <div>
      <div className="faq_question" onClick={closeItem}>
        {htmlParser(question)}
        <DownArrow className={`${isOpen ? "is-open" : ""}`} />
      </div>
      <div className={`faq_answer ${isOpen ? "is-open" : ""}`}>
        {htmlParser(answer)}
      </div>
    </div>
  )
}

export default QA
