import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={37} height={21} viewBox="0 0 37 21" {...props}>
      <title>{"Path"}</title>
      <path
        stroke="#FFF"
        strokeWidth={2}
        d="M36.178 1.5L18.5 19.178.822 1.5"
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgComponent
