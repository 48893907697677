import React from "react"
import { Link, graphql } from "gatsby"
import htmlParser from "html-react-parser"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Logo from "../images/logo"
import GeneralSection from "../components/GeneralSection"
import FirstSection from "../components/FirstSection"
import FAQ from "../components/FAQ"

const IndexPage = ({ data }) => {
  const {
    prismicHomepage: {
      data: { approach, contact, faq_dropdown, services, about, first_section },
    },
  } = data

  return (
    <Layout>
      <SEO title="Home" />
      <FirstSection html={first_section.html} />
      <GeneralSection html={about.html} className="section_about" />
      <GeneralSection html={approach.html} className="section_approach" />
      <GeneralSection html={services.html} className="section_services" />
      <FAQ dropdown={faq_dropdown} className="section_faq" />
      <GeneralSection html={contact.html} className="section_contact" />
    </Layout>
  )
}

export const data = graphql`
  query {
    prismicHomepage {
      data {
        first_section {
          html
        }
        about {
          html
        }
        approach {
          html
        }
        contact {
          html
        }
        faq_dropdown {
          answer {
            html
          }
          question {
            html
          }
        }
        services {
          html
        }
      }
    }
  }
`

export default IndexPage
